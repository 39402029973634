import { ajax } from 'jquery'

import { addCSRF, addCSRFtoFormData } from 'lib/utilities'
import { errorFromXhr } from 'lib/utilities/api'



export function rebuildForm(package_id, document_ids) {
  const url = `/package_hook/${package_id}/leases/documents`

  // Use the index as a key in order to maintain ordering
  const doc_entries = document_ids.map((form_id, sort_order) => [sort_order, { form_id, sort_order }])
  const data = addCSRF({ documents: Object.fromEntries(doc_entries) })

  return new Promise((res, rej) => {
    const success = () => res()
    const error = xhr => rej(errorFromXhr(xhr))

    $.ajax({
      url, type: "post", data,
      success, error
    })
  })
}


export function uploadUserFormDocument({package_machine_id, file, name}) {
  const url = `/forms/v3`
  const data = new FormData()

  if (name)
    data.append('form[name]', name)

  data.append('form[form_type]', 'user')
  data.append('form[pdf_file]', file)
  data.append('form[base_id]', package_machine_id)
  addCSRFtoFormData(data)

  return new Promise((res, rej) => {
    const success = data => res(data)
    const error = xhr => errorFromXhr(xhr)

    ajax({
      url, data,
      type: 'post',
      contentType: false,
      processData: false,
      success, error
    })
  })
}

export function getUserForm({package_id, form_id}) {
  const url = `/package_hook/${package_id}/leases/user_form/edit?form_id=${form_id}`


  return new Promise((res, rej) => {
    const success = data => res(data)

    ajax({
      url, success
    })
  })
}
