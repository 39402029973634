import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { merge } from 'lodash'

import getToolbar, { prepareComponents, generateEmpty } from 'components/form_creator/tools/highlight'

import { useGenerateComponent } from './index'
import { setHighlightIntoStore } from '../store/form'

const highlightIsOnPage = (highlight, page_id) => highlight.position.page == page_id

export function useGenerator({keepDrawing} = {}) {
  const dispatch = useDispatch()
  
  return rect => {
    const highlight = merge(generateEmpty(), { position: rect })

    if (keepDrawing)
      highlight.newlyAdded = true

    dispatch(setHighlightIntoStore(highlight))
  }
}

export function useComponents(tools, page_id) {
  const hlghlights = useSelector(state => Object.values(state.form.highlights).filter(h => highlightIsOnPage(h, page_id)))
  const selectedComponents = useSelector(state => state.general.selectedComponents)

  const generateComponent = useGenerateComponent(tools, selectedComponents)

  return prepareComponents('highlight', hlghlights).map(generateComponent)
}

function useApi() {
  // Generate the API
  const dispatch = useDispatch()
  const highlights = useSelector(state => state.form.highlights)

  const update = (id, componentData) => {
    dispatch(setHighlightIntoStore({ id, ...componentData }))
  }

  const create = (componentData, id) => {
    update(id, merge(generateEmpty(), componentData))
  }

  const read = id => highlights[id]

  return { create, read, update }
}

export function useTool() {
  const api = useApi()
  return getToolbar({name: 'highlight', api })
}