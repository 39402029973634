
import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react'
import { configureStore, createSlice } from '@reduxjs/toolkit'
import { Provider, useDispatch, useSelector } from 'react-redux'

import { mapValues, groupBy, compact, isNil, sum } from 'lodash'
import { ajax } from 'jquery'

import SliderToggle from 'components/utility/slider_toggle'

import { DownloadModalButton } from 'components/packages/download_modal'

import Form from 'components/form'
import DocumentsInterstitial from './documents_interstitial'

// import FeesSetModal from './fees/components/modals/set_fees/FeesSetModal'
import GetStartedModal from './documents/components/modals/GetStartedModal'
import { ModalContextProvider, ModalContext } from './ModalContext'
import { LeaseContext, LeaseContextProvider } from './fees/contexts/LeaseContext'
import { StatusContext, StatusContextProvider } from './StatusContext'
import FeeContextProvider, { FeeContext } from './fees/contexts/FeeContext'
import StripeAccContextProvider, { StripeAccContext } from './fees/contexts/StripeAccContext'
import { useEventListeners } from '@deathbyjer/react-event-manager'
// import { combineObjectsWithSummedValues } from '../../../javascript/lib/utilities/array_of_objects'
import DocsReadySetFeesModal from './fees/components/modals/set_fees/DocsReadySetFeesModal'
import ComposedContextProviders from './documents/ComposedContextProviders'

const initialState = {
  packageId: null,
  documents: {},
  roles: {},
  documentsOrder: [],
  currentDocument: null,
  currentIndex: null,
  editing: null,
  interstitialContent: null,
  signing: null,
  loaded: true,

  user_roles: [],
  user_permissions: [],
  signature_count_by_role: {},
  signed_count_by_role: {},
  signatures_left: {}
}

const Store = createSlice({
  name: 'global',
  initialState,
  reducers: {
    loadData(state, { payload: { package_id, documents, roles, user_roles, user_permissions, signature_count_by_role, signed_count_by_role, signatures_left } }) {
      state.packageId = package_id
      state.documents = mapValues(groupBy(documents, 'id'), arr => arr[0])
      state.documentsOrder = documents.map(doc => doc.id)
      state.currentDocument = state.documentsOrder[0]
      state.currentIndex = 0

      state.roles = roles
      state.user_roles = user_roles
      state.user_permissions = user_permissions
      state.signature_count_by_role = signature_count_by_role
      state.signed_count_by_role = signed_count_by_role
      state.signatures_left = signatures_left
    },

    setCurrentDocument(state, { payload: currentDocument }) {
      state.currentDocument = currentDocument
      state.currentIndex = state.documentsOrder.indexOf(currentDocument)
    },

    setEditing(state, { payload: { editing, interstitialContent, signing } }) {
      state.editing = editing
      state.interstitialContent = interstitialContent
      state.signing = signing
    },

    closeEditing(state) {
      state.editing = null
      state.interstitialContent = null
      state.signing = null
    },

    setLoaded(state, { payload: loaded }) {
      state.loaded = loaded
    },

    setSignaturesLeft(state, { payload: {signatures_left} }) {
      state.signatures_left = signatures_left
    }
  }
})

const { loadData, setCurrentDocument, setEditing, closeEditing, setLoaded, setSignaturesLeft } = Store.actions

const store = configureStore({
  reducer: Store.reducer
})

function usePermissionsChecks(permissions) {
  const user_permissions = useSelector(state => state.user_permissions)

  if (!Array.isArray(permissions))
    permissions = [permissions]

  return useMemo(() => {
    const lookups = permissions.map(permission => [permission, user_permissions.includes(permission)])

    return Object.fromEntries(lookups)
  }, [...permissions, user_permissions])
}

function DocumentsHeader(props) {
  const permissions = usePermissionsChecks(['manage-documents', 'review-documents'])

  return <>
    <div className="documents-nav-header">
      <div className="title">
        <div>
          <b>Documents</b>
        </div>
        {permissions['manage-documents'] && <div style={{ fontWeight: 'normal', fontSize: 13 }}><a href="?edit=true"><u>Manage Selection</u></a></div>}
      </div>
      {permissions['review-documents'] ? <ReviewSlider props={props} /> : null}
    </div>

  </>
}

function SingleDocumentInSidebar({ document_id }) {
  const dispatch = useDispatch()
  const document = useSelector(state => state.documents[document_id])
  const isActive = useSelector(state => state.currentDocument == document_id)

  const requiredSignatures = useMemo(() => sum(Object.values(document.required_signatures)), [document.required_signatures])
  const signedSignatures = useMemo(() => sum(Object.values(document.signed_required_signatures)), [document.signed_required_signatures])

  const clickActive = useCallback(() => dispatch(setCurrentDocument(document_id)), [dispatch, document_id])

  const classes = compact([
    isActive ? 'active' : null
  ])

  return <div className={classes.join(" ")} onClick={clickActive}>
    <div className="name">{document.name}</div>
  </div>
}

function DocumentsSidebar() {
  const documentsOrder = useSelector(state => state.documentsOrder)
  return <div className="documents-sidebar">
    {documentsOrder.map(doc_id => <SingleDocumentInSidebar document_id={doc_id} key={doc_id} />)}
  </div>
}

// function FormHeader({empty}) {
//   const classes = compact([
//     "form-area",
//     empty ? 'is-empty' : null
//   ])
//   return <div className={classes.join(" ")}>

//   </div>
// }

function FormArea(props) {
  const document = useSelector(state => state.documents[state.currentDocument])
  const { bumpUp } = props
  const classes = compact([
    'form-area'
    // bumpUp ? "bump-up" : null
  ])

  return <div className={classes.join(" ")}>
    <FormAreaOverlays />
    <Form
      {...props}
      form_id={document.form_instance_id}
      viewOnly={true}
    />
  </div>
}

function FormAreaOverlays() {
  return <div className='overlay documents-overlay'>
    <OverlayButton />
  </div>
}

function OverlayButton() {
  const { userRoles } = useContext(LeaseContext)
  if (userRoles.includes('processor')) {
    return <ProcessorOverlayButtons />
  } else {
    return <DealPartyOverlayButtons />
  }
}

function ProcessorOverlayButtons() {
  const { counterSigned } = useContext(StatusContext)

  switch (true) {
    case counterSigned:
      return null
    default:
      return <EditButton />
  }
}

function DealPartyOverlayButtons() {
  const { submitted, shared, readyToShare, counterSigned } = useContext(StatusContext)

  switch (true) {
    case counterSigned:
      return null
    case submitted:
      return <SignButton disabled={false} />
    // case shared:
    // case readyToShare:
    //   return <SignButton disabled={true} />
    default:
      return <SignButton />
  }
}

function EditButton() {
  const dispatch = useDispatch()
  const currentDocument = useSelector(state => state.currentDocument)
  const onEdit = useCallback(() => dispatch(setEditing({ editing: currentDocument })), [dispatch, currentDocument])

  const style = {
    display: 'block',
    top: 10,
    margin: '0 auto',
    position: 'relative',
  }

  return <button className="btn btn-bper pill-btn" onClick={onEdit} style={style}>Edit Documents</button>
}

function SignButton(props) {
  const dispatch = useDispatch();
  const currentDocument = useSelector((state) => state.currentDocument);
  const { permissions } = useContext(LeaseContext)

  if (!permissions.includes('sign_documents'))
    return

  const handleClick = useCallback(() => {
    dispatch(setEditing({ editing: currentDocument, signing: true }));
  }, [dispatch, currentDocument]);

  const style = {
    display: 'block',
    top: 10,
    margin: '0 auto',
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #56A256',
    color: '#56A256',
    ...(props.disabled && {
      opacity: 1,
      border: '1px solid #DBDBDB',
      backgroundColor: '#E5E5E5'
    })
  };

  const buttonProps = {
    style: style,
    className: 'btn pill-btn',
    onClick: handleClick,
    disabled: props.disabled
  };

  return <button {...buttonProps}>Sign Documents</button>;
}

function ReviewSlider(props) {
  const [prepping, setPrepping] = useState(isNil(props.defaultPrepping) ? !props.defaultPrepping : true)
  const [swapping, setSwapping] = useState(false)
  const { setCurrentModal } = useContext(ModalContext)
  const { setReadyToShare, readyToShare } = useContext(StatusContext)

  const onChange = useCallback(on => {
    if (swapping)
      return

    toggleReview()
    setPrepping(!on)
    setSwapping(true)
    setReadyToShare(on)

    setTimeout(() => setSwapping(false))
  }, [swapping, setSwapping, setPrepping])

  useEffect(() => {
    if (prepping || props.feesConfirmed)
      return

    setCurrentModal(<DocsReadySetFeesModal />)
  }, [prepping])

  return <SliderToggle onByDefault={readyToShare} offText="Reviewing" onText="Finished" onChange={onChange} bubbleIcon={true} />
}

function toggleReview() {
  $.ajax({
    data: {},
    url: "leases/documents/review",
    dataType: "json",
    method: "POST"
  })
}

function shouldShowGetStartedModal(roles) {
  // const { userRole } = useContext(LeaseContext)
  // const { setCurrentModal } = useContext(ModalContext)
  // const documents = useSelector(state => state.documents)
  // const user_roles = useSelector(state => state.user_roles)

  // if deal party
  if (roles.includes('tenant')) {
    // if has any signatures left to sign or any fees not authorized
    // const requiredSignatureCounts = combineObjectsWithSummedValues(Object.values(documents).map(d => d.required_signatures))
    // const signedSignatureCounts = combineObjectsWithSummedValues(Object.values(documents).map(d => d.signed_required_signatures))

    // todo
    return true
    // debugger
  }
}

const ProcessorPrepText = () => {
  const { submitted, shared, counterSigned } = useContext(StatusContext)

  switch (true) {
    case counterSigned:
      return <div><b>Review fully signed Agreement.</b> Download a copy for your records.</div>
    case submitted:
      return <div>Review documents and select <span style={{ color: '#398439' }}><b>Sign Documents</b></span> to begin countersigning.</div>
    case shared:
      return <div>
        <p><b>Document signing in progress.</b></p>
        <p>Unlock documents to add additional documents or edits.</p>
      </div>
    default:
      return <div><b>Edit documents as needed.</b> When complete, lock documents from further edits.</div>
  }
}

const DealPartyPrepText = () => {
  const { permissions } = useContext(LeaseContext)

  if (permissions.includes("sign_documents"))
    return <p>Review documents and select <span style={{ color: '#56A256' }}>Sign Document</span> to begin countersigning.</p>
  else
    return <p>Review documents as needed. <span style={{ fontWeight: 300 }}>Your signatures are not required.</span></p>
}

const Prep_Text = () => {
  const { userRoles } = useContext(LeaseContext)

  if (userRoles.includes('processor')) {
    return <ProcessorPrepText />
  } else {
    return <DealPartyPrepText />
  }
}

const PrepHeader = React.memo(() => {
  return <>
    <div className="text">
      <div className='instruction'><Prep_Text /></div>
    </div>
    <div>
      <HeaderButtons />
    </div>
  </>
})

const HeaderButtons = () => {
  const { userRoles } = useContext(LeaseContext)

  if (userRoles.includes('processor')) {
    return <ProcessorHeaderButtons />
  } else {
    return <DealPartyHeaderButtons />
  }
}

const ProcessorHeaderButtons = () => {
  const dispatch = useDispatch()
  const currentDocument = useSelector(state => state.currentDocument)
  const onEdit = useCallback(() => dispatch(setEditing({ editing: currentDocument, interstitialContent: 'role_assignment' })), [dispatch, currentDocument])
  const packageId = useSelector(state => state.packageId)

  return <>
    <ViewFullScreenBtn canDownload={true} />
    <button className='btn btn-default' onClick={onEdit}>Roles + Permissions</button>
  </>
}

const DealPartyHeaderButtons = () => {
  // todo: add onclick after compilation
  return <>
    <ViewFullScreenBtn canDownload={false} />
  </>
}

// todo: set onclick after compilation
const ViewFullScreenBtn = ({ canDownload }) => {
  const { counterSigned } = useContext(StatusContext)
  const [opened, setOpened] = useState(false)

  if (counterSigned)
    return null

  const style = { marginRight: 10 }

  return <>
    <button className='btn btn-default' style={style} onClick={() => setOpened(true)}>
      View Full Screen
    </button>
    {opened ? <FullScreen canDownload={canDownload} onClose={() => setOpened(false)} /> : null}
  </>
}

const FullScreen = ({ canDownload, onClose }) => {
  const order = useSelector(state => state.documentsOrder)
  const documents = useSelector(state => state.documents)
  const [hovering, setHovering] = useState(false)
  const packageId = useSelector(state => state.packageId)

  useEffect(() => {
    document.body.classList.add("full-screen-opened")
    return () => document.body.classList.remove("full-screen-opened")
  }, [])

  const closeClasses = compact([
    'btn',
    'btn-neutral-secondary',
    hovering ? 'hovering' : null
  ]).join(' ')

  return <>
    <div className="full-screen-screen" />
    <div className="full-screen">
      <div className="full-screen-header">
        <div className="centered-container">
          <div className="building-info"></div>
          <div className="buttons">
            {canDownload && <DownloadModalButton className="btn btn-positive-secondary" style={{ marginRight: "10px" }} packageId={packageId}>Download</DownloadModalButton>}
            <button className={closeClasses} onClick={onClose} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>Close</button>
          </div>
        </div>
      </div>
      <div className="forms centered-container" style={{ maxWidth: 1400 }}>
        {order.map(id => <Form key={id} form_id={documents[id].form_instance_id} viewOnly={true} />)}
      </div>
    </div>
  </>
}

const SignatureCount = React.memo(({ role_id }) => {
  const role = useSelector(state => state.roles[role_id])
  const signed_count = useSelector(state => state.signed_count_by_role[role_id] || 0)
  const required_count = useSelector(state => state.signature_count_by_role[role_id] || 0)

  const allSigned = signed_count == required_count
  const notStarted = signed_count == 0

  const signingStatusStyle = allSigned || notStarted ? 'status-light' : 'status-dark'

  return <div className={'all-signatures-needed-container ' + `${signingStatusStyle}`}><span className="signature-count-container">
    {!allSigned ? <span className="signature-count">{signed_count} / {required_count}</span> : null}
    <span className="role-label">
      {allSigned ? <i className='fa-light fa-check' style={{ color: '#57A257' }} /> : null}
      {role.label}
    </span>
  </span>
  </div>
})

function SignatureCounts({ filter_role_ids }) {
  const roles = useSelector(state => state.roles)
  const signatures = useSelector(state => state.signature_count_by_role)

  let role_ids = useMemo(() => {
    let role_ids = Object.keys(signatures)
    role_ids = role_ids.filter(id => roles[id] && signatures[id] > 0)
    return role_ids.sort((a, b) => roles[a].order - roles[b].order)
  }, [roles, signatures])

  return (
    <div className="signature-counts-container">
      {role_ids.length > 0
        ? role_ids.map(role_id => <SignatureCount key={role_id} role_id={role_id}></SignatureCount>)
        : <p>None required.</p>
      }
    </div>
  )
}

// function signatureCountForRole(documents, role) {
//   const requiredSignatureCounts = combineObjectsWithSummedValues(Object.values(documents).map(d => d.required_signatures))
//   const signedSignatureCounts = combineObjectsWithSummedValues(Object.values(documents).map(d => d.signed_required_signatures))

//   return requiredSignatureCounts[role] - signedSignatureCounts[role]
// }

const ProcessorHeader = (props, setCurrentModal) => {
  return <>
    <div className="main-header-section">
      <div>
        <b>Completed Digital Signatures:</b>
        <SignatureCounts />
      </div>
      {/* cut out for beta since no notarize <div className="file-certificate-orange"></div> */}
    </div>
    <DocumentsHeader setCurrentModal={setCurrentModal} documentsReviewed={props.documents_reviewed} feesConfirmed={props.has_fees_been_confirmed} />
  </>
}

const DealPartyHeader = (props, setCurrentModal) => {
  const signaturesLeft = useSelector(state => sum(Object.values(state.signatures_left)))

  return <>
    <div className="main-header-section">
      <div>
        <b>Your Remaining Signatures: </b>&nbsp;{signaturesLeft}
      </div>
    </div>
    <DocumentsHeader setCurrentModal={setCurrentModal} documentsReviewed={props.documents_reviewed} feesConfirmed={props.has_fees_been_confirmed} />
  </>
}

const StatusHeader = React.memo(({ props, setCurrentModal }) => {
  const { permissions } = useContext(LeaseContext)

  if (permissions.includes('view-processor-documents-status-header'))
    return <ProcessorHeader props={props} setCurrentModal={setCurrentModal} />

  else if (permissions.includes('view-documents-status-header'))
    return <DealPartyHeader props={props} setCurrentModal={setCurrentModal} />
})

// function Modal

function useReload() {
  const dispatch = useDispatch()

  return (package_id) => new Promise((res) => {
    const url = `/package_hook/${package_id}/leases/documents`
    //dispatch(setLoaded(null))

    const success = (data) => {
      dispatch(loadData(data))
      //dispatch(setLoaded(package_id))
      res()
    }

    ajax({ url, success })
  })
}

function updateSignaturesLeft() {
  const dispatch = useDispatch()
  return (package_id) => new Promise((res) => {
    const url = `/package_hook/${package_id}/leases/documents`

    const success = (data) => {
      const signaturesLeft = data.signatures_left
      dispatch(setSignaturesLeft({signatures_left: signaturesLeft}))
      res()
    }

    ajax({ url, success })
  })
}

function Main(props) {
  const { package_id, remainingBalance } = props
  const { id, form_instance_id: form_id } = useSelector(({ editing, documents }) => editing ? documents[editing] : {})
  const { interstitialContent, signing, documents } = useSelector(state => state)
  const { currentModal, setCurrentModal } = useContext(ModalContext)
  const { userRoles, permissions } = useContext(LeaseContext)
  const [loading, setLoading] = useState(false)

  const signaturesLeft = useSelector(state => sum(Object.values(state.signatures_left)))
  const reload = useReload()

  const dispatch = useDispatch()
  const onCloseInterstitial = useCallback(() => {
    setLoading(true)
    dispatch(closeEditing())
    reload(package_id).then(() => setLoading(false))
  }, [package_id])

  const emptyFormHeader = true
  const canSign = permissions.includes('sign_documents')
  const canViewStatusHeader = permissions.includes('view-documents-status-header')
  const canViewProcessorHeader = permissions.includes('view-processor-documents-status-header')

  useEffect(() => {
    if (shouldShowGetStartedModal(userRoles)) {
      setCurrentModal(<GetStartedModal requiredSignaturesRemaining={signaturesLeft} remainingBalance={remainingBalance} />)
    }
  }, [])

  return <>
    <div className={`documents-header ${!canSign && !canViewProcessorHeader || !canViewStatusHeader ? 'cannot-sign' : ''} top-header`}>
      <PrepHeader />
    </div>
    <div className={`documents-header ${!canViewStatusHeader ? 'hide-status-header' : ''} second-header`}>
      <StatusHeader props={props} setCurrentModal={setCurrentModal} />
      {loading && <div className="loading"></div>}
    </div>
    <div className="leases-fill-documents-main">
      <div className="main-area">
        {!loading && <FormArea {...props} bumpUp={emptyFormHeader} />}
        <DocumentsSidebar />
        <div className='documents-sidebar-empty-padding'></div>
      </div>
      {loading && <div className="loading"></div>}
    </div>
    {form_id ? <DocumentsInterstitial {...props} content={interstitialContent} signing={signing} document_id={id} form_id={form_id} onClose={onCloseInterstitial} /> : null}
  </>
}

function Loader(props) {
  const { package_id } = props
  const { setSubmitted } = useContext(StatusContext)
  const { setCurrentModal } = useContext(ModalContext)
  const [loaded, setLoaded] = useState(false)
  const reload = useReload()
  const update = updateSignaturesLeft()

  useEffect(() => {
    reload(package_id).then(() => setLoaded(package_id))
  }, [package_id])

  useEventListeners({
    'allSignaturesSigned': () => setSubmitted(true),
    'signatureAdded': () => update(package_id)
  })

  if (package_id != loaded)
    return <div className="loading"></div>

  return <>
    <Main {...props} />
  </>
}

const providers = props => {
  const { cards, bank_accounts, all_payments, user_payment, user_id, ACH_FEE, CARD_RATE, fees_submitted, capture_initiated_at, ...rest } = props

  return [
    <LeaseContextProvider
      electronicSigningConsentedAt={props.electronic_signing_consented_at}
      remainingRequiredSignatureIdsByRole={props.remaining_required_signature_ids_by_role}
      permissions={props.permissions}
      userRoles={props.roles}
      signaturesLeft={props.signatures_left}
    />,
    <FeeContextProvider {...rest} />,
    <StripeAccContextProvider
      cards={cards}
      bank_accounts={bank_accounts}
      all_payments={all_payments}
      user_payment={user_payment}
      user_id={user_id}
      ACH_FEE={ACH_FEE}
      CARD_RATE={CARD_RATE}
      fees_submitted={fees_submitted}
      capture_initiated_at={capture_initiated_at} />,
    <ModalContextProvider />,
    <StatusContextProvider sidebarStatuses={props.sidebar_statuses} userRoles={props.roles} permissions={props.permissions} />,
  ]
}

export default function (props) {
  return <Provider store={store}>
    <ComposedContextProviders providers={providers(props)}>
      <Loader {...props} />
    </ComposedContextProviders>
  </Provider>
}
