import React, { useCallback, useEffect } from 'react'
import ComposedContextProviders from './documents/ComposedContextProviders'
import LeaseContextProvider from './share/context/LeaseContext'
import FeeContextProvider from './fees/contexts/FeeContext'
import StripeAccContextProvider from './fees/contexts/StripeAccContext'
import { ModalContextProvider } from './ModalContext'
import { StatusContextProvider } from './StatusContext'

import { createSlice, configureStore } from '@reduxjs/toolkit'
import { Provider, useSelector, useDispatch } from 'react-redux'

import { compact, sum } from 'lodash'
import ReactTooltip from 'react-tooltip'
// import UploadArea from 'components/utility/upload_area'

import { rebuildForm, uploadUserFormDocument } from './api'
// import EditCustomForm from './edit_custom_form'


const initialState = {
  package_id: null,
  package_machine_id: null,
  possible_lease_types: [],
  lease_type: null,
  possible_riders: [],
  riders: [],

  form_drafts: [],
  custom_forms: []
}

const Store = createSlice({
  name: "global",
  initialState,
  reducers: {
    loadIntoStore(state, { payload: { package_id, package_machine_id, lease_type, riders, possible_riders, possible_lease_types }}) {
      state.package_id = package_id
      state.package_machine_id = package_machine_id
      state.possible_lease_types = possible_lease_types
      state.lease_type = lease_type
      state.possible_riders = possible_riders // full rider objects
      state.riders = riders // array of ids
    },

    setLeaseType(state, { payload: lease_type}) {
      state.lease_type = lease_type
    },

    addRider(state, { payload: rider_id }) {
      if (state.riders.includes(rider_id))
        return

      const riders = state.riders.concat([rider_id])

      state.riders = state.possible_riders.filter(rider => riders.includes(rider.id)).map(rider => rider.id)
    },

    removeRider(state, { payload: rider_id }) {
      const riders = state.riders.filter(id => rider_id != id)

      state.riders = state.possible_riders.filter(rider => riders.includes(rider.id)).map(rider => rider.id)
    },

    // addCustomFormDraft(state, { payload: custom_form}) {
    //   state.form_drafts = state.form_drafts.concat([custom_form])
    // },

    // confirmCustomFormDraft(state, { payload: form_id }) {

    // }
  }
})

const { loadIntoStore, setLeaseType, addRider, removeRider} = Store.actions

const store = configureStore({
  reducer: Store.reducer
})

function Button(props) {
  const onClick = () => props.onClick()
  const defaultClassNames = 'btn btn-default select-doc-button'
  const classes = compact([defaultClassNames, props.checked ? "checked" : null])

  const tooltipContent = <div className='tooltip-content'>{props.children || props.title}</div>
  const tooltipAttributes = {
    'data-for': `select-document-${props.id}`,
    'data-tip': '',
    'data-effect': 'solid'
  }

  return <>
    <button className={classes.join(" ")} onClick={onClick} {...tooltipAttributes}>
      <span>{ props.children || props.title }</span>
      {props.checked ? <i className="fa-regular fa-check" style={{ color: '#56A256' }} /> : null}
    </button>
    <ReactTooltip id={`select-document-${props.id}`} className='bp-default-tooltip' getContent={() => tooltipContent} arrowColor="transparent" />
  </>
}

function LeaseTypes() {
  const dispatch = useDispatch()
  const types = useSelector(state => state.possible_lease_types)
  const selected = useSelector(state => state.lease_type)
  const setSelected = type_id => dispatch(setLeaseType(type_id))

  return <>
    <p>Select Lease Type</p>
    <div className="select-lease-type">
      {types.map(type => <Button key={`lease-${type.id}`} id={type.id} checked={selected == type.id} onClick={() => setSelected(type.id)}>{type.title}</Button>)}
    </div>
  </>
}

function Riders() {
  const dispatch = useDispatch()
  const riders = useSelector(state => state.possible_riders) // array of full rider objects
  const selected = useSelector(state => state.riders) // array of ids

  const inSelected = rider => selected.some(selectedRider => selectedRider == rider.id)
  const select = rider => dispatch(addRider(rider.id))
  const deselect = rider => dispatch(removeRider(rider.id))
  const onClick = rider => { inSelected(rider) ? deselect(rider) : select(rider)}

  return <>
    <div>
      <span>
        Choose Riders
      </span>
      <span style={{ fontSize: 14, fontWeight: 'lighter', marginBottom: 6, float: 'right' }}>By default, all riders associated with this building have been pre-selected.</span>
    </div>
    <div className="select-riders">
      {riders.map(rider => <Button key={`rider-${rider.id}`} id={rider.id} checked={inSelected(rider)} onClick={() => onClick(rider)}>{rider.title}</Button>)}
    </div>
  </>
}

// function CustomForms() {
//   const package_machine_id = useSelector(state => state.package_machine_id)

//   const dispatch = useDispatch()
//   const uploadFiles = useCallback(files => {
//     uploadUserFormDocument({
//       package_machine_id,
//       file: files[0].file
//     }).then(form => {
//       dispatch(addCustomFormDraft(form))
//     })
//   })

//   const uploadAreaTextJsx = <div style={{ textAlign: 'center', margin: 10 }}>
//     <div><h4 style={{ marginBottom: 0 }}><i className='fa-light fa-cloud-arrow-up' /> Drag & Drop or <u>Choose Image</u></h4></div>
//     <div>Must be either a .png, .jpg, or .jpeg</div>
//   </div>

//   return <div className="custom-user-forms">
//     <h4>Add Additional Documentation <span style={{ fontWeight: 'lighter' }}>(Optional)</span></h4>
//     <h4 style={{ display: 'inline', }}>Please note:</h4> <span style={{ fontSize: 18, fontWeight: 'lighter' }}>Any uploaded files <span style={{ fontWeight: 'normal' }}>cannot</span> be autofilled or signed. Text overlay tools are available.</span>
//     <div style={{marginTop: 15}}>
//       <UploadArea inputChanged={uploadFiles} exclude_icons={true} text={uploadAreaTextJsx} />
//     </div>
//   </div>
// }

// function CustomFormDraft() {
//   const package_id = useSelector(state => state.package_id)
//   const form = useSelector(state => state.form_drafts[0])

//   if (!form)
//     return null

//   return <EditCustomForm package_id={package_id} form_id={form.id}  />
// }

function GenerateButton({package_id, document_count, style}) {
  const lease_type = useSelector(state => state.lease_type)
  const riders = useSelector(state => state.riders)

  const disabled = lease_type ? false : true
  const classes = compact(['btn', disabled ? ' disabled' : ' btn-success-filled'])

  const onClick = () => {
    if (disabled)
      return

    rebuildForm(package_id, compact([lease_type].concat(riders)))
      .then(() => window.location = `/package_hook/${package_id}/documents`)
      .catch(errors => {
        console.log(errors)
      })
  }

  const buttonText = () => `Generate ${document_count} Documents`

  return <button disabled={disabled} className={classes.join("")} onClick={onClick} style={style}>
    {buttonText()}
  </button>
}

function SelectDocuments(props) {
  const dispatch = useDispatch()
  const inDraft = useSelector(state => state.form_drafts.length > 0)
  const leaseTypesCount = useSelector(state => state.lease_type ? 1 : 0)
  const ridersCount = useSelector(state => state.riders ? state.riders.length : 0)

  useEffect(() => {
    dispatch(loadIntoStore({
      package_id: props.package_id,
      lease_type: props.selected_lease_type,
      riders: props.selected_riders,
      possible_riders: props.riders,
      possible_lease_types: props.lease_types
    }))
  }, [])


  // if (inDraft) {
  //   return <CustomFormDraft />
  // }

  const containerStyle = { display: 'flex', marginBottom: 25 }
  const itemNumberStyle = { fontSize: 16, flex: '0 0 20px' }
  const itemTitleStyle = { fontSize: 16, flexGrow: 1 }

  return <div className="select-documents">
    <div style={containerStyle}>
      <div style={itemNumberStyle}>1. </div><div style={itemTitleStyle}><LeaseTypes /></div>
    </div>
    <div style={containerStyle}>
      <div style={itemNumberStyle}>2. </div><div style={itemTitleStyle}><Riders /></div>
    </div>
    {/* <div style={containerStyle}>
      <div style={itemNumberStyle}>3. </div><div style={{ flexGrow: 1 }}><CustomForms /></div>
    </div> */}
    <GenerateButton package_id={props.package_id} document_count={sum([leaseTypesCount, ridersCount])} style={{position: 'absolute', right: 30}} />
  </div>
}


export default function (props) {
  const providers = props => {
    const { cards, bank_accounts, all_payments, user_id, ACH_FEE, CARD_RATE, ...rest } = props

    return [
      <LeaseContextProvider
        electronicSigningConsentedAt={props.electronic_signing_consented_at}
        remainingRequiredSignatureIdsByRole={props.remaining_required_signature_ids_by_role}
        permissions={props.permissions}
        userRoles={props.roles}
      />,
      <FeeContextProvider {...rest} />,
      <StripeAccContextProvider
        cards={cards}
        bank_accounts={bank_accounts}
        all_payments={all_payments}
        user_id={user_id}
        ACH_FEE={ACH_FEE}
        CARD_RATE={CARD_RATE} />,
      <ModalContextProvider />,
      <StatusContextProvider sidebarStatuses={props.sidebar_statuses} userRoles={props.roles} permissions={props.permissions} />,
    ]
  }

  return <Provider store={store}>
    <ComposedContextProviders providers={providers(props)}>
      <SelectDocuments {...props} />
    </ComposedContextProviders>
  </Provider>
}
